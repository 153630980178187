@import '~react-agenda/build/styles.css';
@import '~react-datetime/css/react-datetime.css';
@import './grid';

/*@font-face {
    font-family: 'DINpro';
    src: local('Dinpro'), url('./fonts/DINPro-Black tr.ttf') format('truetype');
}*/
@font-face {
    font-family: 'DINpro';
    font-weight:bold;
    src: url('../fonts/DINPro-Bold tr.ttf') format('truetype');
}
@font-face {
    font-family: 'DINpro';
    font-weight:100;
    src: url('../fonts/DINPro-Light tr.ttf') format('truetype');
}
@font-face {
    font-family: 'DINpro';
    src: url('../fonts/DINPro-Medium tr.ttf') format('truetype');
}

$gt-red: #A92E0C  ;
$gt-red-light: #a84d34  ;
$lightBlue: #0F9DE5;
$red: #d11317 ;
$green: #00f2aa;
$grey: #F0F0F0 ;

.page-wrapper {
    padding-bottom:100px;
    padding-left:20%;
    padding-right:20%;
}

input {
    font-family: 'DINpro', sans-serif;
}

select {
    height:40px !important;
}

textarea {
    padding-top: 10px;
}

h1 {
    font-size:32px;
    //font-weight:bold;
    font-family: 'Bebas Neue', cursive;
}

h2 {
    font-size: 25px;
    font-weight:500;
    font-family: 'Bebas Neue', cursive;
}

h3 {
    font-size: 20px;
    font-family: 'Bebas Neue', cursive;
}

a {
    text-decoration: none;
    color: unset;
}

button {
    font-family: 'DINpro', sans-serif;
}

body {
    font-size: 14px;
    font-family: 'DINpro', sans-serif;
    font-variant-ligatures: no-common-ligatures;
    margin:0;
    overflow-x: hidden;
}


input {
    outline: none;
    border: 4px solid;
    font-variant-ligatures: no-common-ligatures;
}

.ck-editor {
    width: -moz-available !important;
    width: available !important;

    .ck-content {
        min-height:113px;
    }
}

.image-picker {
    height:70vh;
    width:60vw;
    background-color:#EEE;
    border-radius:10px;
    position:fixed;
    top: (100vh/2)-(70vh/2);
    left: (100vw/2)-(60vw/2);
    z-index: 500;
    padding:50px;
}
.image-picker-media {
    height:70vh;
    width:60vw;
    background-color:#EEE;
    border-radius:10px;
    // position:fixed;
    // top: (100vh/2)-(70vh/2);
    // left: (100vw/2)-(60vw/2);
    // z-index: 500;
    padding:50px;
}

.form-wrapper {
    width:1045px;
    max-width:100%;
    background-color:#F0F0F0;
    border-radius:10px;
    margin:0 auto;
    text-align: left;
    padding:50px;
    color:$gt-red;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .fieldset-wrapper {
        margin:50px 15%;

        input {
            width: -moz-available;
            width: available;
        }

        // img {
        //     width:35px;
        //     height:35px;
        //     margin:0;
        // }
        &.form-row
        {
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            justify-content: space-between;

        }
    }

    .white-background {
        background-color: white;
        border-radius:10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border:1px solid #C4C4C4;
        padding: 15px;
        margin: 20px 0;
        width:fit-content
    }

    .fieldset-title {
        font-size:20px;
        line-height:23px;
        text-transform: uppercase;
    }

}

.button {
    cursor: pointer;
    height: 48px;
    width:145px;
    width:fit-content;
    border-radius:10px;
    background:white;
    padding: 10px;
    color: $gt-red;
    font-size:15px;
    line-height: 18px;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
    border:1px solid $gt-red;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    &-primary {
        height: auto;
    }
    &:hover {
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
    }
}

.button-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    &:hover {
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
    }
}

.date-picker {
    height:20px;
    width:100px !important;
    padding:10px 10px
}

.bg-gray {
    background-color: lightgray;
}

.buton-link {
    text-decoration:none;
    height:fit-content;
}

.page-title {
    color:$gt-red;
}

.table-wrapper {
    //margin-right:20%;
    //margin-left:20%;
    table {
        width:119%;
        margin: 0 auto;

        thead {
            tr {
                background-color: $gt-red;
                color:white;

                th {
                    padding: 6px;
                    //width: 20%;
                    font-size: 14px;
                    line-height:16px;
                    text-transform: uppercase;
                    vertical-align: middle;
                }
            }
        }

        tbody {
            tr {
                color:$gt-red;
                a {
                    color:$gt-red;
                }
                &:nth-of-type(2n) {
                    // background-color: #BBBBBB;
                    color:white;

                    a {
                        color:white;
                    }
                }
                td {
                    padding: 6px;
                    width: 20%;
                    vertical-align: middle;
                }
            }
        }
    }
}

.pagination {
    display:flex;
    flex-direction:row;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 20px 0 20px 0;
    font-size:25px;
    line-height: 29px;
    color:$gt-red;

    .prev-next {
        border:2px solid $gt-red;
        border-radius: 10px;
        padding:0px 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        text-decoration:none;
        &:hover {
            box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
        }
        &:visited {
            color:$gt-red;
        }
    }

    .page-number {
        margin:0 10px;

        &:visited {
            color:$gt-red;
        }
    }
}

.agenda__table {
    table{
        th, td{

            border-color: $lightBlue !important;
            color: #4b4b4b
        }
    }
    .dragDiv {
        width:100%;
    }

    .agenda__cell:hover {
        background: rgba(15, 158, 229, 0.11);
    }
}

.rdtPicker {
    width:fit-content;
    border:none;

    table {
        border:none;
        th {
            border:none;
        }
        td {
            border:none;
        }
    }

    .rdtCounters {
        border-radius: 10px;
        border:1px solid #F5F5F5;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.25)
    }
}

.page-subtitle {
    color:$gt-red;
    font-size:20px;
    line-height:23px;
    text-align:left;
}

.alveole {
    height:240px;
    width:240px;
    color:$gt-red;
    border-radius:10px;
    margin: 0 30px;
    margin-bottom:60px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    &:hover {
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
    }
}

.grey-background {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px auto;
    //margin-right:20%;
    //margin-left:20%;
    //width: 1150px;
    //max-width: 80vw;
    background-color: rgb(240, 240, 240);
    padding: 20px 50px;
    border-radius: 10px;
    color: rgb(5, 120, 190);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.good-notif {
    margin: 30px 10%;
    border-radius: 10px;
    background-color: rgb(0, 158, 25);
    padding: 20px;
    color: white;
}

.bad-notif {
    margin: 30px 10%;
    border-radius: 10px;
    background-color: rgb(182, 5, 5);
    padding: 20px;
    color: white;
}

.actu-card {
    display:flex;
    flex-direction:row;
    padding:3% 80px 0 80px;

    img {
        margin:0;
    }

    div {
        text-align: left;
        margin-left:40px;
    }

    h3 {
        color:white;
    }

    p {
        color:white;
    }
}

.blue-box {
    background-color: $gt-red;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0,0,0,0.25);
}

.input-file {
    border:none;
}


::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
    background-color: $gt-red;
    outline: 1px solid white;
    border:2px solid $gt-red-light;
    border-radius: 500px;
}

.icon-text-button {
    border-radius:10px;
    background-size:cover;
    background-repeat:no-repeat;
    color:white;
    font-size:25px;
    font-weight:500;
    text-transform:uppercase;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    padding:10px 50px;
    max-width: 100%;
    max-height:120px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            margin:0;
            margin-right:40px;
        }
    }

    @media screen and (max-width:479px) {
        flex-direction: column;
        max-height:fit-content;

        img {
            //display:none;
        }

        div {
            flex-direction: column;
            justify-content: center;

            img {
                margin-right:0;
            }
        }
    }
}

.actu-area-title {

    display:flex;
    flex-direction:row;
    align-items:center;

    @media screen and (max-width:479px) {
        div {
            display:none;
        }
    }
}

.link-text-bottom-corner {
    background-size:cover;
    background-repeat:no-repeat;
    border-radius:10px;
    box-sizing:border-box;
    height:141px;
    width:45%;
    position:relative;

    @media screen and (max-width:479px) {
        width:100%;
    }

    p {
        position:absolute;
        bottom:15px;
        left:30px;
        color:white;
        text-transform:uppercase;
        font-size:15px;
        font-weight:bold;
    }
}

.ajout-fichier {
    .input-button {
        height:100%;
        width:100%;
    }
}

.actu-bottom {
    text-decoration:none;
    position: fixed;
    bottom:-8px;
    left:20vw;
    width:60vw;
    border:none;
    padding:0;
    border-radius:10px 10px 0 0;
    overflow:hidden;
    @media screen and (max-width:768px) {
        left:5vw;
        width:90vw;
    }
}

.devise
{
    font-size: 1.5em;
    margin-top: .3em;
    width: 12%;
}

label
{
    display: block;
}

.container-column-half {
    display:flex;
    flex-direction:column;
    width:48%;
    label {
        margin-top:20px;
        width:100%;
    }
}

label.half-row
{
    width: 50%;
    margin-right: 15px;
    margin-top: 1em;
    input, select
    {
        display: block;
        margin-top: .5em;
    }
    &:last-child
    {
        margin-right: 0px;
    }
}

label.quarter-row
{
    width: 25%;
    margin-right: 15px;
    margin-top: 1em;
    input, select
    {
        display: block;
        margin-top: .5em;
    }

    &:last-child
    {
        margin-right: 0px;
    }
}

label.third-row
{
    width: 33%;
    margin-right: 15px;
    margin-top: 1em;
    input, select
    {
        display: block;
        margin-top: .5em;
    }

    &:last-child
    {
        margin-right: 0px;
    }
}

h4
{
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
}

div.image-container
{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.image-button
{
    cursor: pointer;
    &.upload-button
    {

    }
}

.image-frame
{
    margin: 20px 0;
    width: 100%;
    max-height: 500px;
    text-align: center;

    img
    {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: 0;
        width: unset;
        height: unset;
    }
}

.column-container
{
    display: flex;
    flex-direction: row;
    .form-column img
    {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        margin: 0;
        width: unset;
        height: unset;
    }
}

.screenshot-container
{
    display: flex;
    flex-direction: column-reverse;
    width: 33%;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: flex-end;
}

.screenshot-thumbnail
{
    width: unset;
    height: unset;
    max-height: 50px;
    margin: 0;
    transition: max-height 1s;
    &:hover
    {
        max-height: 300px;
    }
}

.partner-image-container
{
    width: 30%;
    background-color: rgba(127, 127, 127, 0.2);
    border-radius: 5px;
    padding: 5px;
    img { border-radius: 5px;}
    p { text-align: center;}
}

.fa-button
{
    color: $gt-red;
    cursor: pointer;
    &.plus { font-size: 2.2em; }
    &.close { margin-left: 5px; }
}

.pays {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: $gt-red;
    border-radius: 50%;
}
.flag {
    font-size: 20px;
}

#translation textarea {
    font-family: 'Arial !important', sans-serif;
}

.participations {
    max-width: 100%;
    th {
        padding: 2px !important;
        vertical-align:middle;
    }
}

.participations-page-wrapper {
    margin: 0 20px 0 20px;
    padding-bottom: 100px;
}

.challenges {
    max-width: 100%;
    th {
        padding: 2px !important;
        vertical-align:middle;
    }
}

.challenges-page-wrapper {
    margin: 0 20px 0 20px;
    padding-bottom: 100px;
}

.ant-modal {
    width: 70% !important;
}

.ant-modal-body {
    max-height: 80vh;
    overflow: auto;
}
