input.color {
  border: none;
  background-color: transparent;
}

.sup-o {
  &::after {
    content: 'o';
    position: relative;
    bottom: 5px;
    text-transform: lowercase;

  }
}

.table-wrapper {
  tr{
    td{
      width: auto!important;
    }
  }
  .sortable {
    cursor: pointer;

    &.asc {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        border: solid rgb(255, 255, 255);
        border-width: 3px 3px 0 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 0;
        height: 0;
      }
    }

    &.desc {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        border: solid rgb(255, 255, 255);
        border-width: 3px 3px 0 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto 0;
        width: 0;
        height: 0;
      }
    }
    .firstname {
      right: -5px;
    }
  }
}


input.codeCourseFilter::placeholder {
  font-size: 0.725em;
  font-weight: bold;
}

.row {
  display: flex;
  flex-grow: 1;
  margin-left: -7px;
  margin-right: -7px;
  .col {
    flex-grow: 1;
    padding-left: 7px;
    padding-right: 7px;

    select,
    input {
      margin-top: .5em;
    }

  }


  >div {
    padding-left: 7px;
    padding-right: 7px;
    select,
    input {
      margin-top: .5em;
    }
  }
}