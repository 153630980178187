@import '../../style/style.scss';

.header-wrapper {
    background:#AB3217;
    width:100vw;
    height: 200px;

    img {
        height:165px;
        width:241px;
    }
}

.menu-wrapper-burger {
    border-right:solid;
    border-right-color: #AB3217;
    position:fixed;
    top:0px;
    right:0;
    width:300px;
    height:100%;
    background-color:#F4F5F7;
    //height:calc(100% - 200px);
    //max-height:calc(100vh - 200px);
    display:flex;
    flex-direction:column;
    color:#464646;
    text-transform: uppercase;
    font-weight:bold;
    transition: left 1s;
    z-index:500;
    padding-left:20px;

    a {
        font-size:20px;
        width:100px;
        margin-top:25px;
        position: relative;
        display: flex;
        flex-direction: column;
        //line-height: 25px;
        text-align: left;
        font-weight:bold;
        font-family: 'DINpro', sans-serif;

        &:hover {
            font-size:22px;
            transition: all 0.5s
        }
    }

    #scroll {
        direction:rtl;
    }

    .underline:after {
        content: "";
        //position: absolute;
        //bottom:-8px;
        //left:0;
        //margin:8px;
        height: 0;
        background-color: $red;
        width: 100px;
        border: 2px solid $red;
        border-radius: 50px;
    }
}

.burger-icon-wrapper {
    transition:all 1s;
}

.burger {
    width:22px;
    height:4px;
    background-color:$gt-red;
    border-radius:10px;
    transition:all 1s;
    position:absolute;

    &:nth-of-type(1){
        top:0;
    }
    &:nth-of-type(2){
        top:8px;
    }
    &:nth-of-type(3){
        top:16px;
    }
}


.burger-cross {
    position:absolute;
    transform: rotate(-45deg);
    top:8px !important;
}
.burger-cross:nth-of-type(2n) {
    position:absolute;
    transform: rotate(45deg);
    top:8px !important;
}

.menu-wrapper {
    position: relative;
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items: center;
    margin:10px auto;
    height:38px;
    width: fit-content;
    max-width: 100vw;

    @media screen and (max-width:479px) {
        margin:10px 0;
    }

    a {
        color:white;
        text-transform: uppercase;
        text-decoration: none;
        margin:0 40px;
        font-size:15px;
        font-weight: 500;
        line-height: 18px;
        display:flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;


        &.button-header {
            width:fit-content;
            border-radius:10px;
            border: none;
            background:white;
            padding: 10px 25px;
            height:fit-content;
            color: $gt-red;
        }
    }



    .espace-etablissement {
        position: absolute;
        top:-100px;
        right:-15%;
        display:flex;
        flex-direction:column;
        width:fit-content;
        justify-content: center;
        align-items: center;

        img {
            width:23px;
            height:23px;
            margin:0;
            margin-bottom:8px;
        }

        a {
            margin:0;

        }

        .red-line {
            margin-top:5px;
            min-height:0px;
            width:122px;
            background-color: $red;
            border:2px solid $red;
            border-radius: 50px;
        }
    }
}

.underline:after {
    content: "";
    //position: absolute;
    //bottom:-0px;
    height: 0;
    background-color: $red;
    width: 100px;
    border: 2px solid $red;
    border-radius: 50px;
}
