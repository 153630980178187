@import '../../style/style.scss';

h1 {
    color: white;
    text-transform: uppercase;
    margin-bottom:50px;
    text-align: left;
    align-self: flex-start;
}

.connection-wrapper {
    display:flex;
    flex-direction:column;
    align-items: center;
}

.input-text {
    width:96%;
    border-radius:10px;
    border: none;
    height: 34px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-left:1em;
}

.input-submit {
    height:38px;
    width:145px;
    width:fit-content;
    border-radius:10px;
    border: none;
    background:white;
    padding: 10px;
    color: $gt-red;
    font-weight: bold;
    font-size:15px;
    line-height: 18px;
    margin-top:20px;
    box-shadow: 0px 4px rgba(0, 0, 0, 0.25);
}

.inscription-link {
    //height:38px;
    width:145px;
    width:fit-content;
    border-radius:10px;
    border: none;
    background:#F33C3C;
    padding: 10px;
    text-transform: uppercase;
    color: white;
    //font-weight: bold;
    font-size:15px;
    line-height: 18px;
    margin-top:20px;
    text-decoration: none;
    vertical-align: middle;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    &:hover {
        box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.15) !important;
    }
}

.input-wrapper {
    display:flex;
    flex-direction:column;
    align-items: flex-start;
    margin-bottom: 20px;
    color:black;
    width:100%;

    label {
        margin-bottom:3px;
        font-weight: 500;
        font-size: 25px;
        line-height: 29px;
    }
}

.forget-link {
    margin-top:30px;
    color: white;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
}

img {
    height:306px;
    width: 422px;
    margin-bottom: 50px;
}

.connection-wrapper {
    background-color: $gt-red;
    width:450px;
    border-radius:10px;
    margin: auto;
    margin-top: 10px;
    padding:70px 100px;
    //max-height: calc(80vh - 200px);
    overflow-y: scroll;
}

.home-wrapper {
    //height: calc(100vh - 200px);
    //max-height: calc(100vh - 200px);
    overflow-y: scroll;
    display:flex;
    justify-content: center;
    align-items: center;
    background-size: 100vw calc(100vh - 200px);
}
